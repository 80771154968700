body {
  font-family: Roboto Arial, Helvetica, sans-serif;
  color: #3e3e3e;
}
main {
  min-height: 80vh;
}

.rating span {
  margin: 0.1rem;
}

.rating svg {
  color: #f8e825;
}

.rating-text {
  font-size: 0.8rem;
  font-weight: 600;
  padding-left: 0.5rem;
}

.product-title-link {
  font-size: 1.15rem;
  text-decoration: none;
}

.product-title {
  /* Set font-size */
  font-size: 0.95rem;
  /*  Set a fixed height */
  height: 1.75rem;
  /* Add bottom-margin to seperate content below */
  margin-bottom: 1rem;
  /* Hide overflow content */
  /* overflow: hidden;  */
  /* Add ellipsis for long text */
  text-overflow: ellipsis; 
  /* Prevent wrapping */
  white-space: wrap; 
}

product-card-title {
  height: 0;
}

.card-title {
  margin-bottom: 0;
}

.room-container .list-group-item {
  border: none;
  padding: 1rem 0.5rem 0;
}

.room-container .range-value {
  padding-left: 0.5rem; 
  font-size: 0.85rem;
  color: #6c6c6c;
}

.range-value {
  margin-bottom: 1.25rem;
}

.slide-container .title-box, .title-box {
  width: 100%;
  border-bottom: 1px solid #ddd;
  margin-bottom: 1.75rem;
  font-weight: bold;
}

.categories-box .title-box {
  margin-bottom: 0.75rem;
  font-weight: bold;;
}

.title-box p {
  margin-bottom: 0.25rem;
}

.title-text-box {
  padding: 2.5rem 5rem 1rem 0;
}

.two-up-reverse .title-text-box {
  padding: 2.5rem 0 1rem 5rem;
}

.title-text-box h3 {
  font-weight: bold;
}

.title-text-box p {
  line-height: 1.65rem;
}

.two-up-reverse {
  flex-direction: row-reverse;
}

.two-up, .two-up-reverse {
  align-items: center;
  margin: 5rem 0;
}

.two-up a, .two-up-reverse a {
  text-decoration: none;
  font-size: 1.1rem;
}

.two-up-image img {
  width: 100%;
}

.showcase-container {
  background-color: #d8d7d6;
  font-size: 1.35rem;
  color:#212529;
  padding: 8rem 15rem;
  line-height: 2.25rem;
  text-align: center;
  margin-bottom: 4.75rem;
}

.style-tips-container {
  margin: 3rem 0;
}

.style-tips-container h1 {
  /* font-family: Marcellus, Arial, Helvetica, sans-serif; */
  margin-bottom: 0.5rem;
  font-weight: bolder;
}

.style-tips-container h4 {
  font-weight: bolder;
  margin-top: 3rem;
}

.style-tips-container li {
  margin-bottom: 1rem;
  list-style-type: none;
  line-height: 1.75rem;
  font-size: 1.1rem;
}

.style-tips-container li img {
  width: 95%;
  margin: 2rem auto;
  text-align: center;
  display: table;
}

.style-tips-container ul {
  margin-bottom: 2rem;
  padding: 0;
}

.style-tagline-box {
  margin-bottom: 2rem;
  font-size: 1.35rem;
}

.style-box {
  background-color: #d8d7d6;
  text-align: center;
  padding:3rem 5rem;
  font-size: 1.25rem;
  margin: 2rem 0;
  line-height: 2.5rem;
}

.slide-container p {
  margin-bottom: 0.25rem;
}

.filter-box {
  padding-right: 1rem;
}

table td,
table th {
  text-align: center;
}

input[type="text"] {
  padding-left: 0.5rem;
  border: 1px solid #6c6c6c;
  color: #8c8c8c;
}

.review {
  margin-top: 30px;
}

.review h2 {
  font-size: 24px;
  background: #f4f4f4;
  padding: 10px;
  border: 1px solid #ddd;
}

.review button {
  margin-top: 10px;
}

/* .carousel-caption {
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
} */

.navbar {
  padding-bottom: 0;
}

.navbar-nav input {
  position: relative;
  box-shadow: none;
  border: none;
  border-radius: 0%;
  width: 100%;
  padding: 0.3rem 1rem 0.25rem 0.5rem;
  border-bottom: 1px solid #9ea6ae;
  background-color: none!important;
}

.navbar-nav input:focus { 
  box-shadow: none;
}

.search-button {
  position: absolute;
  bottom: 0px;
  border: none;
  box-shadow: none;
  background-color: none;
  right: 0;
  padding-right: 0;
  color: #3d3c3c;
}

.category-container img {
  width: 60%;
  border-radius: 50%;
}

.category-container p {
  color: #090909;
 font-size: 1.25rem;
}

.brand img {
  width: 33%;
}

/* h3 {
  color: #3e3e3e;
} */

h1.headline pre {
  font-family: Marcellus, Arial, Helvetica, sans-serif;
  text-align: center;
  text-transform: uppercase;
  font-size: 3.5rem;
  color: #090909;
  overflow: hidden;
}

.headline-tag {
  font-family: Sacramento;
  font-size: 8.5rem;text-align: center;
  color: #d7cbb7;
  margin-top: -4rem;
  transform: rotate(-5deg);
}

.heading-bar {
  height: 5rem;
  margin-bottom: 2rem;
}

.top-bar-container {
  background-color: #ddd1c1;
}

.admin-bar .col:last-child {
  margin-right: 0!important;
}

.admin-bar a {
  text-decoration: none;
}

.stock-badge {
  background-color: #9e9e9e;
  color: #fff;
}

.stock-container {
  font-size: 0.95rem;
  font-weight: bold;
}

.contact-us {
  padding-right: 5rem;
}

.contact-us a {
  text-decoration: none;
}

footer {
  background-color: #212529!important
}

footer h3 {
  color: white;
  font-family: Marcellus, Arial, Helvetica, sans-serif;
  margin-bottom: 0.75rem;
}

footer a, footer p {
  text-decoration: none;
  color: #9e9e9e
}

footer p {
  font-family: Roboto Arial, Helvetica, sans-serif;
}
 
footer a {
  display: block;
  margin-bottom: 0.25rem; 
}

footer .info-links col {
  display: flex;
  flex-direction: column;
}

footer .policies, footer .links, footer .contact-us {
  margin-bottom: 1.5rem;
}

.h3 {
  font-size: 1.75rem;
}

@media only screen and (max-width: 1024px) {
  #cart-items {
    align-self: start;
    padding: 0.4rem;
  }
  #cart-items span {
    display: none;
  }

  footer .info-links a {
    display: inline-block;
    margin-bottom: 0.25rem;
    padding-right: 1rem;
  } 
  .showcase-container {
    padding: 3rem 2rem;
  }
}

@media only screen and (max-width: 760px) {
  .brand img {
    width: 40%;
  }
  
  .navbar-nav input {
    width: 100%;
  }

  .headline-tag {
    font-size: 5rem;
  }

  h1.headline {
    font-size: 1.75;
  }

  .category-container img {
    max-width: 50%;
  }

  footer .info-links a {
    display: block;
    margin-bottom: 0.25rem;
    padding-right: 1rem;
  } 

  .showcase-container {
    font-size: 1rem;
    padding: 3rem 2rem;
    line-height: 1.5rem;
    text-align: center;
    margin-bottom: 2.75rem;
  }

  .title-text-box {
    padding-left: 0!important;
  }
  
}

